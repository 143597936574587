/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-18 16:38:09 */

const Keys = {
  paxSelection: 'paxSelection',
  travelDate: 'travelDate',
  hotelName: 'hotelName',
  hotel: 'hotel',
  hotel_plural: 'hotel_plural',
  destination: 'destination',
  destination_plural: 'destination_plural',
  origin: 'origin',
  submit: 'submit',
  invalidInput: 'invalidInput',
  category: 'category',
  travelers: 'travelers',
  select: 'select',
  travelTime: 'travelTime',
  adjustSearch: 'adjustSearch',
  nights: 'nights',
  duration: 'duration',
  travelDuration: 'travelDuration',
  night: 'night',
  night_plural: 'night_plural',
  week: 'week',
  week_plural: 'week_plural',
  arbitrary: 'arbitrary',
  date: 'date',
  pricePerPerson: 'pricePerPerson',
  dateAndDuration: 'dateAndDuration',
  allAirports: 'allAirports',
  apply: 'apply',
  directFlightsOnly: 'directFlightsOnly'
}; export default Keys;