import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Row } from 'reactstrap';
import React, { Fragment, useMemo, useState } from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { useMount } from 'react-use';
import { MEDIAQUERY_DEFAULTS } from '@ibe/components';
import { MagnoliaComponentNode } from '@/Util/magnolia';

type DigitType = '1' | '2' | '3' | '4';
type PropType = 'offerFixedColArea';
type Property = `${PropType}${DigitType}`;
type Props = Property & {
  layout: string;
  className: string;
  spacingBottomDesktop: string;
  spacingBottomMobile: string;
  spacingBottomTablet: string;
  spacingTopDesktop: string;
  spacingTopMobile: string;
  spacingTopTablet: string;
  metadata: MagnoliaComponentNode;
};

const getStyleString = (props: Props, index: string): string => {
  return `
    .offer-fixed-column-layout-column-${index} {
      padding-top: ${props.spacingTopMobile || '10'}px;
      padding-bottom: ${props.spacingBottomMobile || '20'}px;
    }
    
    @media only screen and (min-width: ${MEDIAQUERY_DEFAULTS.md}px) {
        .offer-fixed-column-layout-column-${index} {
          padding-top: ${props.spacingTopTablet || '15'}px;
          padding-bottom: ${props.spacingBottomTablet || '30'}px;
        }
      }
      
    @media only screen and (min-width: ${MEDIAQUERY_DEFAULTS.lg}px) {
      .offer-fixed-column-layout-column-${index} {
        padding-top: ${props.spacingTopDesktop || '20'}px;
        padding-bottom: ${props.spacingBottomDesktop || '40'}px;
      }
    }
  `;
};

const OfferFixedColumnLayout = (props: Props): JSX.Element => {
  const { layout, className, metadata } = props;
  const componentName = metadata['@name'];
  const colWidths = layout.split('x');
  const colCount = colWidths.length;
  const doubleColumnLayout = layout === '8x4-2';
  const [areas, setAreas] = useState<Array<JSX.Element>>([]);
  const [activeSliderIndex, setActiveSliderIndex] = useState<number>(0);
  const [items, setItems] = useState<Array<{ id: number }>>([]);

  const propItems = useMemo(() => {
    return [...Array(colCount).keys()].map(key => {
      return { prop: props[`offerFixedColArea${key + 1}` as keyof Props], key };
    });
  }, [colCount, props]);

  useMount(() => {
    const results: Array<JSX.Element> = [];
    const itemsResults: Array<{ id: number }> = [];
    [...Array(colCount).keys()].map(key => {
      const prop = props[`offerFixedColArea${key + 1}` as keyof Props];
      itemsResults.push({ id: key + 1 });
      results.push(
        <CarouselItem tag="div" key={key + 1}>
          {prop && <EditableArea content={prop} key={key + 1} />}
        </CarouselItem>
      );
      if (doubleColumnLayout && key === 1) {
        const prop = props[`offerFixedColArea${3}` as keyof Props];

        itemsResults.push({ id: 3 });
        results.push(
          <CarouselItem tag="div" key={3}>
            {prop && <EditableArea content={prop} key={3} />}
          </CarouselItem>
        );
      }
    });
    setItems(itemsResults);
    setAreas(results);
  });

  const next = () => {
    const nextIndex = activeSliderIndex === areas.length - 1 ? 0 : activeSliderIndex + 1;
    setActiveSliderIndex(nextIndex);
  };

  const previous = () => {
    const previousIndex = activeSliderIndex === 0 ? areas.length - 1 : activeSliderIndex - 1;
    setActiveSliderIndex(previousIndex);
  };

  const goToIndex = (newIndex: number) => {
    setActiveSliderIndex(newIndex);
  };

  return (
    <>
      <style>{getStyleString(props, componentName)}</style>
      <div className={`offer-fixed-column-layout-column-${componentName} ${className || ''}`}>
        <Row className={`offer-fixed-column-layout-row`} noGutters>
          {propItems.map(({ key, prop }) => {
            const additionalClass =
              (layout === '4x8' && key === 0) || (layout === '8x4' && key === 1)
                ? 'resize-offer-title'
                : '';

            return (
              <Fragment key={key}>
                <Col
                  className={`offerFixedColumnLayoutColumn offerCol-${key} ${additionalClass}`}
                  xs={12}
                  sm={parseInt(colWidths[key], 10)}
                >
                  <EditableArea
                    content={prop}
                    className={doubleColumnLayout && key === 1 ? 'smallOffer topOffer' : 'bigOffer'}
                  />
                  {doubleColumnLayout &&
                    key === 1 &&
                    props[`offerFixedColArea${3}` as keyof Props] && (
                      <EditableArea
                        content={props[`offerFixedColArea${3}` as keyof Props]}
                        className={'smallOffer bottomOffer'}
                      />
                    )}
                </Col>
              </Fragment>
            );
          })}
        </Row>
        <Row className={`offer-carousel-layout-row`} noGutters>
          <Carousel activeIndex={activeSliderIndex} next={next} previous={previous}>
            {items.length > 1 && (
              <CarouselIndicators
                items={items}
                activeIndex={activeSliderIndex}
                onClickHandler={goToIndex}
              />
            )}
            {areas}
            <CarouselControl direction="prev" onClickHandler={previous} />
            <CarouselControl direction="next" onClickHandler={next} />
          </Carousel>
        </Row>
      </div>
    </>
  );
};

export default OfferFixedColumnLayout;
