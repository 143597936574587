import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ApiDurationType, ApiGeoUnit, ApiItemType, ApiListOptions, ApiSortOption } from '@ibe/api';
import {
  DateInputType,
  LoadingOverlay,
  MEDIAQUERY_DEFAULTS,
  PackageDetailsSelection,
  PackageModule,
  PackageParams,
  PackageSearchStore,
  useApi,
  useClientPersistSearchParams,
  useConfig,
  useMediaQuery
} from '@ibe/components';
import RenderSearch from '../render/RenderSearch';
import dayjs from 'dayjs';
import useQuery from '@/Util/useQuery';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import { useRouter } from 'next/router';
import { IbeProps } from '@/components/ibe/IbeProps';
import isClient from '@/Util/globals';
import { useSearchProperties } from '@/Util/useSearchProperties';
import useRemountHooks from '@/components/ibe/useRemountHook';
import RenderSearchContent from '@/components/ibe/render/RenderSearchContent';
import RenderListItem from '@/components/ibe/render/RenderListItem';
import dynamic from 'next/dynamic';
import { useMount } from 'react-use';
import { fetchDestinationsGeoUnits, findDestination } from '@/components/ibe/IbeUtils';
import { configService } from '@/Config/config';

const CustomIndexedDestinationSelect = dynamic(
  () => import('../render/CustomIndexedDestinationSelect'),
  {
    ssr: false
  }
);

const yOffset = -200;

const IbeSearch = observer(function PackageSearch(props: IbeProps) {
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.sm });
  const router = useRouter();
  const query = useQuery();
  const config = useConfig();
  const api = useApi();
  const { ibeUrl, destinationsUrl, triggerSearch } = props;
  const [isLoading, setLoading] = useState(false);
  const [destinations, setDestinations] = useState<Array<ApiGeoUnit>>([]);
  const [defaultParams, setDefaultParams] = useState<Partial<PackageParams>>({
    occupancy: [
      {
        adults: Number.parseInt(props.numberOfAdults || '1'),
        children: Number.parseInt(props.numberOfChildren || '0'),
        infants: 0,
        childrenAges: [],
        infantsAges: []
      }
    ],
    startDate:
      dayjs(props.startDate).format(config.formatDate) ||
      dayjs(new Date()).add(14, 'day').format(config.formatDate),
    endDate:
      dayjs(props.endDate).format(config.formatDate) ||
      dayjs(new Date()).add(28, 'day').format(config.formatDate),
    dateInputType: DateInputType.FULL
  });

  useMount(async () => {
    const destinationsResults = await fetchDestinationsGeoUnits(api);
    setDestinations(destinationsResults);
    if (props.destination) {
      const destination = findDestination(destinationsResults, props.destination);
      if (destination) {
        setDefaultParams(prevState => ({
          ...prevState,
          destinations: destination
        }));
      }
    }
  });

  const { setSearchParams } =
    useClientPersistSearchParams<Partial<PackageParams>>(isClient() ? window || null : null) || {};
  const [prefillOnly, setPrefillOnly] = useState(false);
  const { setSearchProperties: setGlobalSearchProperties } = useSearchProperties();

  const searchParams = useMemo(() => {
    const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    paramsDecoded.dateInputType = DateInputType.FULL;

    if (query.get('destinationCode')) {
      paramsDecoded.destinations = findDestination(
        destinations,
        query.get('destinationCode') || ''
      );
    }

    query.delete('cmspath');
    const searchParameters =
      Object.keys(Object.fromEntries(query.entries())).length > 0
        ? {
            ...paramsDecoded
          }
        : { ...defaultParams };
    return searchParameters;
  }, [query, destinations]);

  const onSelect = (params: PackageDetailsSelection): void => {};

  const updateUrlParams = (params: PackageParams, listOptions: ApiListOptions): void => {
    setLoading(true);
    const queryParams = PackageParamsTransformer.encode(params);
    let url = `${ibeUrl}?${queryParams}`;

    if (params.destinations) {
      const destinations_count = destinations.reduce(
        (count, current) => count + 1 + Object.keys(current.children).length,
        0
      );
      const isSearchForEveryDestination =
        params.destinations.filter(value => value.code).length === destinations_count;

      if (isSearchForEveryDestination && destinationsUrl) {
        setLoading(true);
        const queryParams = PackageParamsTransformer.encode(params);
        url = `${destinationsUrl}?${queryParams}`;
      }
    }

    if (!triggerSearch) {
      setPrefillOnly(false);
    }
    if (!!setSearchParams) {
      setSearchParams(router.asPath.split('?')[0], params);
    }
    if (ibeUrl) {
      router.push(url);
    }
  };

  const updateUrlParamsForSearch = (params: PackageParams, listOptions: ApiListOptions): void => {
    if (params.isFromSearchButton === true) {
      params.goToDetails = true;
    }
    updateUrlParams(params, listOptions);
  };

  const onPageChangeListener = useCallback(() => {
    const { outsideElementsContainerId } = config;
    const findContainer = document.getElementById(outsideElementsContainerId || 'iso');
    const y = (findContainer?.getBoundingClientRect().top || 0) + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }, []);

  const [keys] = useRemountHooks();
  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className={'ibe-search-component'}>
        <PackageModule
          onSelect={onSelect}
          onSearch={updateUrlParamsForSearch}
          onDetail={updateUrlParams}
          searchParams={prefillOnly ? undefined : searchParams}
          showLabels
          subType={ApiItemType.PREDEFINEDPACKAGE}
          showMultipleDurations={false}
          showTotalPriceOnly
          // destinationMandatory //
          endDateMandatory
          withDateRangePicker
          withAdvancedDateRangePicker
          withGroupRequestForm={false}
          withRoomCountSelector={configService.get().enableMultipleRooms}
          renderSearch={RenderSearch}
          renderSearchContent={RenderSearchContent}
          sortingType={{
            GROUP: [],
            SINGLE: [ApiSortOption.NAME, ApiSortOption.PRICE]
          }}
          sorting={[ApiSortOption.NAME, ApiSortOption.PRICE]}
          minDateRangeSpan={2}
          maxDateRangeSpan={200}
          renderListItem={RenderListItem}
          minSelectableChildAge={0}
          noDatePickerOverflow
          selectLabelSort
          withChildrenSelection
          serviceSearchWithChildAgePicker
          changeOnBlur
          widgetClickable
          packageOriginMustBeSet={false}
          packageOriginWithRemoveAll
          packageOriginWithSelectAll
          customIndexedDestinationSelect={(store: PackageSearchStore) => (
            <CustomIndexedDestinationSelect store={store} />
          )}
          dateRangeDurationProps={{
            withDuration: true,
            value: { duration: 7, type: ApiDurationType.DAY },
            usePlaceholderAsOption: false
          }}
          dateRangeUpdateStateFromProps={false}
          dateRangeFillWithLastDateRangeWhenNoSelection
          dateRangeClearSelectionsOnOpen
          doBestPriceSearch
          bestPriceMaxDateRangeSpan={42}
          bestPriceSubmitOnChange
          hideSearchInDetails
          paginationProps={{
            onPageChangeListener
          }}
          key={keys[0]}
          blockInitialSearch
          showPaxSelectionInModal={isMobileView}
          showDateSelectionInModal={isMobileView}
        />
      </div>
    </LoadingOverlay>
  );
});

export default IbeSearch;
