import React, { ReactNode, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react';
import {
  CollapsibleRef,
  DateInputType,
  Overlay,
  PackageModule,
  PackageParams,
  PackageSearchStore,
  useApi,
  useWindow,
  useClientPersistSearchParams
} from '@ibe/components';
import {
  ApiDurationType,
  ApiGetPackagesResponse,
  ApiItemType,
  ApiListOptions,
  ApiSortOption
} from '@ibe/api';
import { useRouter } from 'next/router';
import useQuery from '../../Util/useQuery';
import PackageParamsTransformer from '../../Util/ParamsTransformer/PackageParamsTransformer';
import { stepSessionStorage, validPromotionCodeStorage } from '@/Util/globals';
import RenderSearchContent from './render/RenderSearchContent';
import RenderSearch from './render/RenderSearch';
import RenderListItem from './render/RenderListItem';
import BestPriceSearchContent from './render/BestPriceSearchContent';
import { configService } from '@/Config/config';
import { IbeProps } from '@/components/ibe/IbeProps';
import useRemountHooks from '@/components/ibe/useRemountHook';
import PackageDetails from '@/components/PackageDetails/PackageDetails';
import PriceDetails from '@/components/PackageDetails/PriceDetails';
import { productSearchSessionKey } from '@/Config/config';
import { useMount } from 'react-use';
import {
  enrichSearchParamsCodes,
  fetchDestinationsGeoUnits,
  fetchOriginsGeoUnits,
  UrlEnrichmentData
} from '@/components/ibe/IbeUtils';
import { QueryUtils } from '@/Util/QueryUtils';
import Keys from '@/Translations/generated/en/ibe.json.keys';
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import useTranslation from '@/Util/useTranslation';
import { PrintTableWrapper } from '../PackageDetails/PrintInfo';

const CustomIndexedDestinationSelect = dynamic(
  () => import('./render/CustomIndexedDestinationSelect'),
  {
    ssr: false
  }
);

const Ibe = observer(function Packages(props: IbeProps) {
  const { checkoutUrl } = props;
  const router = useRouter();
  const query = useQuery();
  const api = useApi();
  const window = useWindow();
  const { t } = useTranslation('ibe');
  const persistentSearchParams = useClientPersistSearchParams<Partial<PackageParams>>() || {};
  const [searchClicked, setSearchClicked] = useState(true); // initially true, bc clicked on IbeSearch
  const [noChangeMessageShown, setNoChangeMessageShown] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasBookingError, setBookingError] = useState(false);
  const [urlEnrichmentData, setUrlEnrichmentData] = useState<UrlEnrichmentData | undefined>(
    undefined
  );
  const [searchParams, setSearchParams] = useState<Partial<PackageParams> | undefined>(undefined);
  const [expandForPrint, setExpandForPrint] = useState<boolean>(false);

  const handlePrint = () => {
    setExpandForPrint(true);
    setTimeout(() => {
      window?.print();
    }, 500);
    setTimeout(() => {
      setExpandForPrint(false);
    }, 850);
  };

  useMount(async () => {
    setLoading(true);
    const destinations = await fetchDestinationsGeoUnits(api);
    const origins = await fetchOriginsGeoUnits(api);
    setUrlEnrichmentData({ destinations, origins });
    setLoading(false);
  });

  useEffect(() => {
    if (!searchClicked) {
      return;
    }

    setLoading(true);
    const params = !!persistentSearchParams.getSearchParams
      ? persistentSearchParams.getSearchParams(router.asPath.split('?')[0])
      : undefined;
    const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    paramsDecoded.dateInputType = DateInputType.FULL;

    if (urlEnrichmentData) {
      enrichSearchParamsCodes(paramsDecoded, urlEnrichmentData);
    } else {
      return;
    }
    const searchParameters =
      Object.keys(Object.fromEntries(query.entries())).length > 0
        ? {
            ...paramsDecoded,
            ...params
          }
        : { ...params };
    if (QueryUtils.areEqual(searchParameters, searchParams)) {
      setNoChangeMessageShown(true);
    } else {
      setSearchParams(searchParameters);
      setNoChangeMessageShown(false);
    }
    setLoading(false);
    setSearchClicked(false);
  }, [!!persistentSearchParams.getSearchParams, query.toString(), !!urlEnrichmentData, searchClicked]);

  const updateUrlParams = (params: PackageParams, listOptions: ApiListOptions): void => {
    setLoading(true);
    const queryParams = PackageParamsTransformer.encode(params);
    if (!!persistentSearchParams.setSearchParams) {
      persistentSearchParams.setSearchParams(router.asPath.split('?')[0], params);
    }
    router.push(`${router.asPath.split('?')[0]}?${queryParams}`).finally(() => {
      setLoading(false);
      setSearchClicked(true);
    });
  };

  const shallowUrlUpdate = (params: PackageParams): void => {
    const queryParams = PackageParamsTransformer.encode(params);
    if (!!persistentSearchParams.setSearchParams) {
      persistentSearchParams.setSearchParams(router.asPath.split('?')[0], params);
    }
    router.push(`${router.asPath.split('?')[0]}?${queryParams}`, undefined, { shallow: true });
  };

  const onBestPriceAvailabilitySelect = async (
    packageListResponse: ApiGetPackagesResponse | null,
    searchParams: PackageParams,
    packageCartId?: string
  ): Promise<void> => {
    if (packageCartId) {
      setLoading(true);
      try {
        await api.attemptBooking(packageCartId);
        stepSessionStorage.clear();
        validPromotionCodeStorage.clear();
        sessionStorage.setItem(productSearchSessionKey, query.toString());
        await router.push(checkoutUrl || '');
      } catch (err) {
        console.debug(err);
        setBookingError(true);
      }
      setLoading(false);
    }
  };

  function renderSearchWithMessage(
    CategorySelect: ReactNode,
    OriginSelect: ReactNode,
    DestinationSelect: ReactNode,
    TravelDatePicker: ReactNode,
    TravelMonthPicker: ReactNode,
    TravelerPicker: ReactNode,
    SearchButton: ReactNode,
    OriginCheckboxes: ReactNode,
    DateRangePickerSingleSelect: ReactNode,
    collapsibleRef?: CollapsibleRef
  ) {
    return RenderSearch(
      CategorySelect,
      OriginSelect,
      DestinationSelect,
      TravelDatePicker,
      TravelMonthPicker,
      TravelerPicker,
      SearchButton,
      OriginCheckboxes,
      DateRangePickerSingleSelect,
      collapsibleRef,
      noChangeMessageShown ? t(Keys.noSearchChangeMessage) : undefined
    );
  }

  const [keys] = useRemountHooks();

  function handleBackToListClick() {
    const newParams = { ...searchParams, itemId: undefined };
    setLoading(true);
    const queryParams = PackageParamsTransformer.encode(newParams);
    if (!!persistentSearchParams.setSearchParams) {
      persistentSearchParams.setSearchParams(router.asPath.split('?')[0], newParams);
    }
    router.push(`${router.asPath.split('?')[0]}?${queryParams}`).finally(() => {
      setLoading(false);
      setSearchParams(undefined);
      setSearchClicked(true);
    });
  }

  return (
    <PrintTableWrapper>
      <>
        {searchParams?.itemId && (
          <Button className="hide-in-print mt-3" onClick={handleBackToListClick}>
            {t(Keys.backToList)}
          </Button>
        )}
        {!!searchParams && (
          <PackageModule
            onBestPriceAvailabilitySelect={onBestPriceAvailabilitySelect}
            onSearch={updateUrlParams}
            onDetail={updateUrlParams}
            searchParams={searchParams}
            showLabels
            subType={ApiItemType.PREDEFINEDPACKAGE}
            showMultipleDurations={false}
            showTotalPriceOnly
            destinationMandatory
            endDateMandatory
            withDateRangePicker
            withAdvancedDateRangePicker
            withGroupRequestForm={false}
            withRoomCountSelector={configService.get().enableMultipleRooms}
            renderSearch={renderSearchWithMessage}
            renderSearchContent={RenderSearchContent}
            sortingType={{
              GROUP: [],
              SINGLE: [ApiSortOption.NAME, ApiSortOption.PRICE]
            }}
            sorting={[ApiSortOption.NAME, ApiSortOption.PRICE]}
            minDateRangeSpan={2}
            maxDateRangeSpan={200}
            renderListItem={RenderListItem}
            minSelectableChildAge={0}
            noDatePickerOverflow
            selectLabelSort
            withChildrenSelection
            bestPriceSubmitOnChange
            serviceSearchWithChildAgePicker
            changeOnBlur
            widgetClickable={true}
            packageOriginMustBeSet={false}
            packageOriginWithRemoveAll
            packageOriginWithSelectAll
            customIndexedDestinationSelect={(store: PackageSearchStore) => (
              <CustomIndexedDestinationSelect store={store} />
            )}
            dateRangeDurationProps={{
              withDuration: true,
              value: { duration: 7, type: ApiDurationType.DAY },
              usePlaceholderAsOption: true
            }}
            dateRangeUpdateStateFromProps={false}
            dateRangeFillWithLastDateRangeWhenNoSelection
            doBestPriceSearch
            bestPriceAfterSearch={shallowUrlUpdate}
            bestPriceMaxDateRangeSpan={42}
            bestPriceRenderSearchContent={BestPriceSearchContent}
            bestPriceDesktopSearchCollapse={false}
            bestPriceStickySearch
            ExternalBestPriceDetailsContent={{
              MainContent: props => <PackageDetails {...props} expandForPrint={expandForPrint} />,
              PriceDetails: props => <PriceDetails {...props} onPrintClick={handlePrint} />
            }}
            hideSearchInDetails
            bestPriceListSelectionsMode
            key={keys[0]}
            includeCheapestFlightInRoomPrice
            customSetIsLoading={setLoading}
            bestPriceShowDirectFlightsOnlyFilter={configService.get().showDirectFlightsFilter}
            customListOptions={{
              showFilterInModal: true,
              showApplyButtonInModal: true
            }}
          />
        )}
        {searchParams?.itemId && (
          <Button className="hide-in-print" onClick={handleBackToListClick}>
            {t(Keys.backToList)}
          </Button>
        )}
        <Modal
          isOpen={hasBookingError}
          toggle={() => setBookingError(!hasBookingError)}
          container={document.getElementById('iso') || undefined}
        >
          <ModalBody>
            <div className="text-center">{t(Keys.bookingErrorModalBody)}</div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" onClick={(): void => setBookingError(false)}>
              {t(Keys.bookingErrorModalButton)}
            </Button>
          </ModalFooter>
        </Modal>
        {isLoading && (
          <Overlay
            className="best-price__list-selections__spinner"
            positionFixed={true}
            text={t(Keys.onContinueLoadingText)}
            zIndex={1020}
            customSpinner={
              <div className="best-price-custom-spinner">
                <div />
                <div />
                <div />
                <div />
              </div>
            }
          />
        )}
      </>
    </PrintTableWrapper>
  );
});

export default Ibe;
