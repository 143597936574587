import React, { ReactNode } from 'react';
import {
  ApiBestPricePackageModel,
  ApiComponentInfo,
  ApiComponentType,
  ApiItemType
} from '@ibe/api';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faBus as faBusSolid,
  faPlane,
  faPlane as faPlaneSolid,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { faFly } from '@fortawesome/free-brands-svg-icons';
const defaultIconsConfiguration = new Map<ApiItemType, ReactNode>([
  [
    ApiItemType.HOTEL,
    <FontAwesomeIcon
      key="includedServicesPreview_default_hotel_icon"
      className="mx-1"
      icon={faBed}
    />
  ],
  [
    ApiItemType.FLIGHT,
    <FontAwesomeIcon
      key="includedServicesPreview_default_transport_icon"
      className="ml-1"
      icon={faPlaneSolid}
    />
  ],
  [
    ApiItemType.TRANSFER,
    <FontAwesomeIcon
      key="includedServicesPreview_default_transfer_icon"
      className="mr-1"
      icon={faBusSolid}
    />
  ]
]);

export interface IncludedServicesPreviewProps {
  packageModel: ApiBestPricePackageModel;
  iconsConfiguration?: Map<ApiItemType, ReactNode>;
  className?: string;
}

export function hasAccommodation(components: ApiComponentInfo[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.HOTEL, components);
}
export function hasTransportation(components: ApiComponentInfo[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.TRANSPORT, components);
}
export function hasTransfer(components: ApiComponentInfo[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.TRANSFER, components);
}

export function hasFlight(components: ApiComponentInfo[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.FLIGHT, components);
}

export function hasInsurance(components: ApiComponentInfo[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.INSURANCE, components, true);
}

function hasComponentOfGivenItemType(
  itemType: ApiItemType,
  components: ApiComponentInfo[],
  checkRequired?: boolean
) {
  return components.some(
    component =>
      component.itemType === itemType &&
      (!checkRequired || (checkRequired && component.componentType === ApiComponentType.REQUIRED))
  );
}

const IncludedServicesPreview = (props: IncludedServicesPreviewProps): JSX.Element => {
  const { packageModel, iconsConfiguration, className } = props;
  const { componentTypes } = packageModel;
  const icons = iconsConfiguration || defaultIconsConfiguration;

  return (
    <Container>
      <Row
        className={`iso__includedServicesPreview__container justify-content-end ${
          className ? className : ''
        }`}
      >
        {hasTransfer(componentTypes) && (
          <Col className="col-auto ">
            <Row>{icons.get(ApiItemType.TRANSFER)}</Row>
          </Col>
        )}
        {hasAccommodation(componentTypes) && (
          <Col className="col-auto">
            <Row>
              <FontAwesomeIcon icon={faPlus} /> {icons.get(ApiItemType.HOTEL)}
            </Row>
          </Col>
        )}
        {hasFlight(componentTypes) && (
          <Col className="col-auto">
            <Row>
              <FontAwesomeIcon icon={faPlus} /> {icons.get(ApiItemType.FLIGHT)}
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default IncludedServicesPreview;
