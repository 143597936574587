/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-18 16:38:08 */

const Keys = {
  noBooking: 'noBooking',
  summary: 'summary',
  order: 'order',
  prev: 'prev',
  next: 'next',
  details: 'details',
  perPerson: 'perPerson',
  TravelDates: 'TravelDates',
  Extras: 'Extras',
  optionalExtras: 'optionalExtras',
  optional: 'optional',
  CustomerDetails: 'CustomerDetails',
  Confirmation: 'Confirmation',
  summaryTotalPrice: 'summaryTotalPrice',
  summaryAcceptDGR: 'summaryAcceptDGR',
  summaryDGRLink: 'summaryDGRLink',
  summaryAcceptDGRAfterLink: 'summaryAcceptDGRAfterLink',
  summaryAcceptTaC: 'summaryAcceptTaC',
  summaryPrivacyPolicy: 'summaryPrivacyPolicy',
  summaryAnd: 'summaryAnd',
  summaryTermsAndConditions: 'summaryTermsAndConditions',
  summaryAcceptDGRAndTaCError: 'summaryAcceptDGRAndTaCError',
  summaryAcceptDGRError: 'summaryAcceptDGRError',
  summaryAcceptTaCError: 'summaryAcceptTaCError',
  summaryMarketingConsent: 'summaryMarketingConsent',
  SummaryBookingError: 'SummaryBookingError',
  ConfirmationHeadline: 'ConfirmationHeadline',
  ConfirmationBookingNumber: 'ConfirmationBookingNumber',
  ConfirmationQuotationBookingNumber: 'ConfirmationQuotationBookingNumber',
  errorAdultCount: 'errorAdultCount',
  errorChildCount: 'errorChildCount',
  errorChildrenAge: 'errorChildrenAge',
  errorInfantCount: 'errorInfantCount',
  errorMinLength: 'errorMinLength',
  errorMaxLength: 'errorMaxLength',
  errorInvalidName: 'errorInvalidName',
  errorInvalidFirstMiddleName: 'errorInvalidFirstMiddleName',
  errorInvalidChar: 'errorInvalidChar',
  errorRequiredField: 'errorRequiredField',
  errorInvalidAdult: 'errorInvalidAdult',
  errorInvalidChild: 'errorInvalidChild',
  errorInvalidInfant: 'errorInvalidInfant',
  travelParticipantsHeader: 'travelParticipantsHeader',
  errorEmail: 'errorEmail',
  errorPhone: 'errorPhone',
  errorInvalidAgencyId: 'errorInvalidAgencyId',
  errorInvalidAgentName: 'errorInvalidAgentName',
  bookingAttemptErrorText: 'bookingAttemptErrorText',
  select: 'select',
  remove: 'remove',
  fillWithMock: 'fillWithMock',
  participantData: 'participantData',
  applicant: 'applicant',
  participant: 'participant',
  requiredFields: 'requiredFields',
  tac_header: 'tac_header',
  tac_firstSlot: 'tac_firstSlot',
  tac_secondSlot: 'tac_secondSlot',
  tac_thirdSlot: 'tac_thirdSlot',
  tac_mandatoryFieldError: 'tac_mandatoryFieldError',
  voucherSection: {
    header: 'voucherSection.header',
    label: 'voucherSection.label',
    apply: 'voucherSection.apply',
    remove: 'voucherSection.remove',
    largerValueModalHeader: 'voucherSection.largerValueModalHeader',
    largerValueModalContent: 'voucherSection.largerValueModalContent',
    largerValueModalButton: 'voucherSection.largerValueModalButton',
    invalidCode: 'voucherSection.invalidCode'
  },
  promotion: {
    header: 'promotion.header',
    label: 'promotion.label',
    apply: 'promotion.apply',
    remove: 'promotion.remove',
    priceReduction: 'promotion.priceReduction',
    inputPlaceholder: 'promotion.inputPlaceholder',
    codeIsNotValid: 'promotion.codeIsNotValid',
    newCodeIsNotValidButTheOldOneWorks: 'promotion.newCodeIsNotValidButTheOldOneWorks'
  },
  createQuoteBookingButtonLabel: 'createQuoteBookingButtonLabel',
  traveler: 'traveler',
  firstPreference: 'firstPreference',
  secondPreference: 'secondPreference',
  thirdPreference: 'thirdPreference',
  fourthPreference: 'fourthPreference',
  fifthPreference: 'fifthPreference',
  headlineText: 'headlineText',
  disclaimerText: 'disclaimerText'
}; export default Keys;