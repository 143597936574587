import NextImage, { ImageProps } from 'next/image';
import { MagnoliaDamNode } from '@/Util/magnolia';
import React from 'react';
import RenditionEnum from '@/Util/RenditionEnum';

interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  showFallbackImage?: boolean;
  image?: MagnoliaDamNode;
  alt?: string;
  src?: ImageProps['src'];
  rendition?: RenditionEnum;
  noLazy?: boolean;
}

// checks whether the src is for sure available, add a fallback image/data
const ImageWrapper = (props: Props) => {
  const { showFallbackImage, noLazy, ...rest } = props;
  const { src, image, alt, title, width, height, rendition = RenditionEnum.R_1600 } = rest;

  let newSrc;
  if (
    rendition &&
    rendition !== RenditionEnum.R_ORIGIN &&
    image?.renditions &&
    image?.renditions[rendition]
  ) {
    newSrc = src || image.renditions[rendition].link;
  } else {
    newSrc = src || image?.['@link'];
  }
  if (showFallbackImage && !newSrc) {
    newSrc = '/assets/fallbackImage.svg';
  }

  return newSrc ? (
    <NextImage
      {...rest}
      src={newSrc}
      alt={alt || image?.metadata?.caption || ''}
      title={title || image?.metadata?.title}
      width={width || image?.metadata?.width || 100}
      height={height || image?.metadata?.height || 100}
      loader={showFallbackImage && !newSrc ? p => p.src : undefined}
      loading={!noLazy ? 'lazy' : 'eager'}
    />
  ) : (
    <></>
  );
};

export default ImageWrapper;
