import {
  ApiBookedItem,
  ApiBooking,
  ApiItemType,
  ApiRoomContainer,
  ApiRoomRate,
  ApiTraveler,
  ApiTravelerFromJSON,
  ApiTravelerType
} from '@ibe/api';

const TRAVELER_PLACEHOLDER = 'Traveler';

abstract class BookingUtils {
  static findBookedItem(
    booking: ApiBooking | null | undefined,
    itemId: string
  ): ApiBookedItem | null | undefined {
    if (booking) {
      return booking.bookedItems.find(bookingItem => bookingItem.id === itemId);
    }
    return null;
  }

  static findBookedItemsByType(
    booking: ApiBooking | null | undefined,
    itemType: ApiItemType
  ): ApiBookedItem[] | undefined {
    if (booking === null || booking === undefined) {
      return undefined;
    } else {
      return booking.bookedItems.filter(
        (bookedItem: ApiBookedItem) => itemType === bookedItem.itemType
      );
    }
  }

  static getTravelersRates(roomRate: ApiRoomRate, fallback?: ApiRoomContainer): Array<ApiTraveler> {
    const travelers: Array<ApiTraveler> = [];

    for (let i = 0; i < (roomRate.paxInfo.adults || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.ADULT
      } as ApiTraveler);
    }

    for (let i = 0; i < (roomRate.paxInfo.children || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.CHILD,
        age: roomRate.paxInfo.childrenAges[i]
      } as ApiTraveler);
    }

    for (let i = 0; i < (roomRate.paxInfo.infants || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.INFANT,
        age: roomRate.paxInfo.infantsAges.at(i) || fallback?.infantsAges.at(i)
      } as ApiTraveler);
    }

    return travelers;
  }

  static buildTravelersForQuoteBookingWithMultiRoom(
    booking: ApiBooking | undefined | null
  ): Array<ApiTraveler> {
    const bookedHotels = BookingUtils.findBookedItemsByType(booking, ApiItemType.HOTEL) || [];

    if (booking && bookedHotels.length >= 1) {
      const mappedTravelers = bookedHotels.map((hotel, index) => {
        const personsIds: String[] = [];

        Object.keys(hotel.priceByPersonId).forEach(item => personsIds.push(item));
        const relatedTravelers = booking.travelers.filter(traveler =>
          personsIds.includes(traveler.id)
        );

        return relatedTravelers.map((traveler, idx) => {
          return this.buildTravelerForQuoteBooking(booking, traveler, idx);
        });
      });

      return mappedTravelers.flat();
    }

    return [];
  }

  static buildTravelersForQuoteBookingWithOneRoom(
    booking: ApiBooking | undefined | null
  ): Array<ApiTraveler> {
    if (!booking) {
      return [];
    }
    return (booking.travelers || []).map((traveler, index) => {
      return this.buildTravelerForQuoteBooking(booking, traveler, index);
    });
  }

  static buildTravelerForQuoteBooking(
    booking: ApiBooking,
    traveler: ApiTraveler,
    index: number
  ): ApiTraveler {
    const age = booking?.travelers.find(trav => trav.id === traveler.id)?.age;

    return ApiTravelerFromJSON({
      id: traveler.id,
      salutation: null,
      firstName: TRAVELER_PLACEHOLDER,
      lastName: index + 1,
      age: age,
      type: traveler.type
    });
  }
}

export default BookingUtils;
