import React, { useMemo, useState } from 'react';
import { ApiBestPricePackageModel, ApiComponent, ApiRoomContainer } from '@ibe/api';
import { Button } from 'reactstrap';
import Keys from '../../Translations/generated/en/package-details.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faBed, faPlusSquare, faBus } from '@fortawesome/free-solid-svg-icons';
import { useAppService, useConfig, Price, PackageSearchParams, useWindow } from '@ibe/components';
import dayjs from 'dayjs';
import { DETAILS_INCLUDED_SERVICES_ID } from '@/components/PackageDetails/PackageDetails';
import { scrollToAnchor } from '@/Util/globals';
import IncludedServicesPreview, {
  hasInsurance,
  hasTransfer
} from '@/components/IncludedServicesPreview/IncludedServicesPreview';
import useTranslation from '@/Util/useTranslation';

const PriceDetails = (props: {
  item: ApiBestPricePackageModel;
  searchParams: PackageSearchParams;
  hideButton?: boolean;
  onPrintClick: () => void;
}): JSX.Element => {
  const { item, searchParams, hideButton, onPrintClick } = props;
  const config = useConfig();
  const app = useAppService();
  const { t } = useTranslation('package-details');
  const window = useWindow();

  const numberOfPersons = useMemo(() => {
    return (
      searchParams?.occupancy?.reduce((total: number, current: ApiRoomContainer) => {
        return total + (current.adults || 0) + (current.children || 0) + (current.infants || 0);
      }, 0) || 0
    );
  }, [searchParams]);

  const room = useMemo(() => {
    return item.rooms.find(room => !!room.defaultMeal) || item.rooms[0];
  }, [item]);

  const handleButtonClick = (): void => {
    scrollToAnchor(DETAILS_INCLUDED_SERVICES_ID, window);
  };

  const componentTypes = item.componentTypes;

  return (
    <div className="bestprice-price-details hide-in-print">
      <div className="d-flex justify-content-between mb-3">
        <div className="bestprice-price-details__icons">
          <IncludedServicesPreview packageModel={item} />
        </div>
        <Button color="primary" className="bestprice-price-details__print" onClick={onPrintClick}>
          {t(Keys.print)}
        </Button>
      </div>
      <div className="bestprice-price-details__content__container">
        <div className="bestprice-price-details__icon">
          <FontAwesomeIcon icon={faPlane} />
        </div>
        <div className="bestprice-price-details__content">
          <div className="mb-2">{`${item.originName} ${item.origin} - ${
            item?.geoAssignment?.geoUnit.name || ''
          } ${item?.geoAssignment?.geoUnit.code || ''}`}</div>
          <div className="d-flex">
            <span>{`${dayjs(item.startDate).format(config.displayFormatDate[app.lang])} ${
              item.origin
            }`}</span>
            <span className="bestprice-price-details__flight-separator mx-2" />
            <span>{item?.geoAssignment?.geoUnit.code || ''}</span>
          </div>
          <div className="d-flex">
            <span>{`${dayjs(item.endDate).format(config.displayFormatDate[app.lang])} ${
              item?.geoAssignment?.geoUnit.code || ''
            }`}</span>
            <span className="bestprice-price-details__flight-separator mx-3" />
            <span>{item.origin}</span>
          </div>
        </div>
      </div>
      <div className="bestprice-price-details__content__container">
        <div className="bestprice-price-details__icon">
          <FontAwesomeIcon icon={faBed} />
        </div>
        <div className="bestprice-price-details__content">
          <div className="mb-2">{item.name}</div>
          <div>{room.description}</div>
          {numberOfPersons > 0 && (
            <div>{`${numberOfPersons} ${t(Keys.person, { count: numberOfPersons })}`}</div>
          )}
          <div>{room.defaultMeal.description || ''}</div>
        </div>
      </div>
      {componentTypes.length > 0 &&
      (hasTransfer(componentTypes) || hasInsurance(componentTypes)) ? (
        <>
          <div className="bestprice-price-details__divider" />
          <div className="bestprice-price-details__components">
            {hasTransfer(componentTypes) && (
              <div className="bestprice-price-details__content__container">
                <div className="bestprice-price-details__icon">
                  <FontAwesomeIcon icon={faBus} />
                </div>
                <div className="bestprice-price-details__content">
                  <div className="mb-2">{t(Keys.transfers)}</div>
                  <div>{t(Keys.transferIncluded)}</div>
                </div>
              </div>
            )}
            {hasInsurance(componentTypes) && (
              <div className="bestprice-price-details__content__container">
                <div className="bestprice-price-details__icon">
                  <FontAwesomeIcon icon={faPlusSquare} />
                </div>
                <div className="bestprice-price-details__content">
                  <div className="mb-2">{t(Keys.insurance)}</div>
                  <div>{t(Keys.insuranceIncluded)}</div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="bestprice-price-details__total">
        <div>{t(Keys.totalPrice)}</div>
        <div className="bestprice-price-details__total__separator">
          {[...Array(20).keys()].map(_ => '______________').join('')}
        </div>
        <div className="bestprice-price-details__total__price">
          <Price price={item.price} />
        </div>
      </div>
      {!hideButton && (
        <Button color="primary" className="w-100 mt-3" onClick={handleButtonClick}>
          {t(Keys.datesAndPrices)}
        </Button>
      )}
    </div>
  );
};

export default PriceDetails;
