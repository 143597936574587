import React, { useState } from 'react';
import { MagnoliaDamNode } from '@/Util/magnolia';
import { Overlay, Price } from '@ibe/components';
import sanitize from '@/Util/sanitize';
import { useMount } from 'react-use';
import usePackageService from '@/Hooks/usePackageService';
import { ApiDurationFactory } from '@/Util/Factory/ApiDurationFacotry';
import { ApiItemType, ApiPrice } from '@ibe/api';
import { ApiRoomContainerFactory } from '@/Util/Factory/ApiRoomContainerFactory';
import useDateFormatter from '@/Hooks/useDateFormatter';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import ImageWrapper from '@/components/ImageWrapper';
import RenditionEnum from '../../Util/RenditionEnum';

export interface OfferProps {
  packageCode?: string;
  hotelCode: string;
  fromText?: string;
  image?: MagnoliaDamNode;
  description?: string;
  startDate?: string;
  endDate?: string;
  duration?: number;
  numberOfAdults?: number;
  offerLink?: string;
  noLazyImage?: boolean;
  priorityImage?: boolean;
}

const Offer = (props: OfferProps) => {
  const packageService = usePackageService();
  const globalMGLProps = useGlobalMGLProps();
  const dateFormatter = useDateFormatter();
  const [name, setName] = useState<string>();
  const [image, setImage] = useState<MagnoliaDamNode | undefined>(props.image);
  const [price, setPrice] = useState<ApiPrice>();
  const [stars, setStars] = useState<number>();
  const [link, setLink] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [tags, setTags] = useState<string[]>([]);
  const [hotelLogo, setHotelLogo] = useState<MagnoliaDamNode>();

  useMount(() => {
    setLoading(true);
    if (!!globalMGLProps?.cmsHotels) {
      const cmsHotel = globalMGLProps?.cmsHotels?.find(h => h.code === props.hotelCode);
      if (cmsHotel?.mainImage) {
        setImage(prevState => (prevState ? prevState : cmsHotel.mainImage));
      }
      if (cmsHotel?.hotelLogo) {
        setHotelLogo(cmsHotel?.hotelLogo);
      }
    }

    const rq = {
      packageCode: props.packageCode,
      startDate: dateFormatter.toModel(props.startDate || ''),
      endDate: dateFormatter.toModel(props.endDate || ''),
      duration: ApiDurationFactory.create({ duration: props.duration }),
      roomContainer: ApiRoomContainerFactory.create([{ adults: props.numberOfAdults }]),
      mainServiceCodes: [props.hotelCode],
      subType: ApiItemType.PREDEFINEDPACKAGE,
      componentTypes: [],
      destination: undefined
    };
    packageService
      .searchBestPricePackages(rq)
      .then(p => {
        if (p.packages.length) {
          setPrice(p.packages[0].price);
          setStars(Number(p.packages[0].category?.description || '0'));
          setName(p.packages[0].name);
          const metaTags = p.packages[0].metaInfo
            ? p.packages[0].metaInfo.contentByMetaType?.TAGS?.content
            : undefined;
          if (metaTags) {
            setTags(metaTags.tags as Array<string>);
          }
          const urlParams = PackageParamsTransformer.encode({
            startDate: rq.startDate,
            endDate: rq.endDate,
            duration: props.duration,
            occupancy: rq.roomContainer,
            packageCode: rq.packageCode,
            itemId: p.packages[0].id,
            goToDetails: true,
            mainServiceCodes: rq.mainServiceCodes
          });
          setLink(`${props.offerLink}?${urlParams}`);
        }
      })
      .finally(() => setLoading(false));
  });

  const Tags = (): JSX.Element => (
    <div className={'offer__tags'}>
      {tags.map(tag => (
        <span key={`tag-${tag}`}>{tag}</span>
      ))}
    </div>
  );

  return (
    <a href={link}>
      <div className={'offerContainer'}>
        <ImageWrapper
          image={image}
          quality={5}
          priority={props.priorityImage}
          noLazy={props.noLazyImage}
          showFallbackImage
        />
        <div className="offer-top">
          <div className={'offerTopLeft'}>{Tags()}</div>
          {props.description ? (
            <div
              className={'offerTopRight'}
              dangerouslySetInnerHTML={{ __html: sanitize(props.description || '') }}
            />
          ) : null}
        </div>
        <div className="offer-bottom">
          <div className={'offerBottomLeft'}>
            {name}
            {[...Array(stars)].map(() => '*')}
          </div>

          <div className={'offerBottomRight'}>
            {props.fromText && <div>{props.fromText}</div>}
            <div className={'offerPrice'}>
              <Price price={price} />
              {loading && <Overlay />}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Offer;
