import React from 'react';
import {
  LinkSwitchableMixin,
  MagnoliaComponentNode,
  MagnoliaDamNode,
  customImageSizeSwitchableMixin
} from '@/Util/magnolia';
import styles from './Image.module.scss';
import Link from 'next/link';
import { getNextLinkAttrs } from '../linkList/Link';
import ImageWrapper from '@/components/ImageWrapper';

interface Props extends LinkSwitchableMixin, customImageSizeSwitchableMixin {
  image: MagnoliaDamNode;
  imageAltText?: string;
  imageCaption?: string;
  keepAspectRatio?: boolean;
  imageCredit?: string;
  imageTitle?: string;
  metadata: MagnoliaComponentNode;
  openNewBrowserWindow?: boolean;
}

const Image = (
  props: Props & LinkSwitchableMixin & customImageSizeSwitchableMixin
): JSX.Element => {
  const {
    image,
    imageAltText,
    imageTitle,
    imageCaption,
    imageSize,
    keepAspectRatio,
    imageSizecustomImageWidth,
    imageSizecustomImageHeight,
    imageCredit,
    linkTypedownload,
    linkTypeexternal,
    linkTypepage
  } = props;
  const imageElement = (
    <ImageWrapper
      className={imageSize}
      style={keepAspectRatio ? { objectFit: 'contain' } : {}}
      image={image}
      width={imageSize === 'custom' ? imageSizecustomImageWidth! : image?.metadata?.width}
      height={imageSize === 'custom' ? imageSizecustomImageHeight! : image?.metadata?.height}
    />
  );
  const linkAttrs = getNextLinkAttrs(props);
  return (
    <figure className={styles.Image}>
      {(linkAttrs?.href && (linkTypedownload || linkTypeexternal || linkTypepage) && (
        <Link {...linkAttrs} locale={linkAttrs.download === true ? false : undefined}>
          {imageElement}
        </Link>
      )) ||
        imageElement}
      {(imageCaption || imageCredit) && (
        <figcaption className={styles.Image__caption}>
          <span>{imageCaption ?? ''}</span>
          {imageCredit && <span>{imageCredit}</span>}
        </figcaption>
      )}
    </figure>
  );
};

export default Image;
