import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingOverlay, useAuth } from '@ibe/components';
import { useMount } from 'react-use';
import { ApiPaymentOption, ApiPriceModifierType } from '@ibe/api';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/Checkout-B2B.json.keys';
import CheckoutStore from '@/components/checkout/CheckoutStore';

const VoucherSection = (props: { store: CheckoutStore }): JSX.Element => {
  const { store } = props;
  const { t } = useTranslationMGL('Checkout-B2B');
  const [code, setCode] = useState('');
  const [isLargerValueVoucher, setIsLargerValueVoucher] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [isVoucherApplied, setIsVoucherApplied] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();

  useMount(() => {
    if (!!store.booking) {
      const voucherPaymentsWithCode = store.booking.payments.filter(
        payment => payment.paymentOption === ApiPaymentOption.VOUCHER && !!payment.voucherCode
      );
      const hasBookingVoucherCode = !!voucherPaymentsWithCode.length;
      setIsVoucherApplied(hasBookingVoucherCode);
      setCode(voucherPaymentsWithCode[0]?.voucherCode || '');
    }
  });

  useEffect(() => {
    setIsSectionVisible(auth.authService.isLoggedIn);
  }, [auth.authService.isLoggedIn]);

  const applyVoucher = useCallback(async () => {
    setIsLoading(true);
    setIsCodeInvalid(false);
    try {
      await store.addVoucherCodesToBooking([code]);
      const booking = store.booking;
      if (!booking) {
        throw 'No booking!';
      }
      const voucherPayments = booking.payments.filter(
        payment => payment.paymentOption === ApiPaymentOption.VOUCHER
      );
      const hasBookingVoucherCode = voucherPayments.length > 0;
      const voucherValue = voucherPayments.at(0)?.amount.finalPrice || 0;
      const appliedVoucherValue =
        booking.price.modifiers.find(m => m.type === ApiPriceModifierType.DISCOUNT)?.absolute || 0;
      if (voucherValue > appliedVoucherValue) {
        setIsLargerValueVoucher(true);
      }
      setIsVoucherApplied(hasBookingVoucherCode);
      setIsCodeInvalid(!hasBookingVoucherCode);
    } catch (err) {
      setIsCodeInvalid(true);
      setIsVoucherApplied(false);
    }
    setIsLoading(false);
  }, [code]);

  const removeVoucher = useCallback(async () => {
    setIsLoading(true);
    await store.addVoucherCodesToBooking([]);
    setIsVoucherApplied(false);
    setCode('');
    setIsCodeInvalid(false);
    setIsLoading(false);
  }, []);

  return (
    <>
      {isSectionVisible && (
        <Row className="formContainer voucherSection_container">
          <Col>
            <LoadingOverlay isLoading={isLoading}>
              <h4>{t(Keys.voucherSection.header)}</h4>
              <h6>{t(Keys.voucherSection.label)}</h6>
              <Row>
                <Col>
                  <Input onChange={input => setCode(input.target.value)} value={code} />
                </Col>
                <Col>
                  {isVoucherApplied ? (
                    <Button onClick={removeVoucher} color="secondary">
                      {t(Keys.voucherSection.remove)}
                    </Button>
                  ) : (
                    <Button onClick={applyVoucher} color="primary">
                      {t(Keys.voucherSection.apply)}
                    </Button>
                  )}
                </Col>
              </Row>
              {isCodeInvalid && (
                <Row>
                  <Col>
                    <span className="small text-danger">{t(Keys.voucherSection.invalidCode)}</span>
                  </Col>
                </Row>
              )}
              <Modal
                className="extraPage_navigation-modal"
                isOpen={isLargerValueVoucher}
                backdrop={true}
                toggle={() => setIsLargerValueVoucher(!isLargerValueVoucher)}
                container={document.getElementById('iso') || undefined}
              >
                <ModalHeader>{t(Keys.voucherSection.largerValueModalHeader)}</ModalHeader>
                <ModalBody>{t(Keys.voucherSection.largerValueModalContent)}</ModalBody>
                <ModalFooter>
                  <Row className="justify-content-end">
                    <Col xs={12} md={'auto'} className="mb-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={(): void => setIsLargerValueVoucher(false)}
                      >
                        {t(Keys.voucherSection.largerValueModalButton)}
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>
            </LoadingOverlay>
          </Col>
        </Row>
      )}
    </>
  );
};

export default VoucherSection;
