import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import DestinationRow, { DestinationDetailsProps, DestinationRowProps } from './DestinationRow';
import { ApiBestPricePackageModel, ApiGeoUnit, ApiPackageModel, ApiItemType } from '@ibe/api';
import { useMount } from 'react-use';
import { fetchDestinationsGeoUnits, findDestination } from '@/components/ibe/IbeUtils';
import {
  LoadingOverlay,
  useApi,
  DateInputType,
  useClientPersistSearchParams,
  PackageParams,
  DestinationItem,
  Overlay
} from '@ibe/components';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import Keys from '@/Translations/generated/en/destinations.json.keys';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { ApiDurationFactory } from '@/Util/Factory/ApiDurationFacotry';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import usePackageService from '@/Hooks/usePackageService';
import { useRouter } from 'next/router';
import useQuery from '@/Util/useQuery';
import useDateFormatter from '@/Hooks/useDateFormatter';

export interface DestinationsProps {
  redirectionUrl: string;
}

const Destinations = (props: DestinationsProps) => {
  const { redirectionUrl } = props;

  const api = useApi();
  const router = useRouter();
  const query = useQuery();
  const dateFormatter = useDateFormatter();
  const packageService = usePackageService();
  const globalMGLProps = useGlobalMGLProps();
  const persistentSearchParams = useClientPersistSearchParams<Partial<PackageParams>>() || {};
  const { t } = useTranslationMGL('destinations');

  const [isLoading, setLoading] = useState<boolean>(false);
  const [destinations, setDestinations] = useState<ApiGeoUnit[]>([]);
  const [packages, setPackages] = useState<ApiPackageModel[]>([]);

  useMount(async () => {
    setLoading(true);
    const destinations: ApiGeoUnit[] = await fetchDestinationsGeoUnits(api);
    setDestinations(destinations);

    const rq = {
      packageCode: searchParameters.packageCode,
      startDate: dateFormatter.toModel(searchParameters.startDate || ''),
      endDate: dateFormatter.toModel(searchParameters.endDate || ''),
      duration: ApiDurationFactory.create({ duration: searchParameters.duration }),
      roomContainer: searchParameters.occupancy,
      destinations: destinations.map(destination => destination.id) || [],
      origins: [],
      subType: ApiItemType.PREDEFINEDPACKAGE,
      componentTypes: [],
      calculatePrices: true,
      facetSearch: true
    };

    packageService
      .searchBestPricePackages(rq)
      .then(p => {
        setPackages(p.packages);
      })
      .finally(() => setLoading(false));
  });

  const params = !!persistentSearchParams.getSearchParams
    ? persistentSearchParams.getSearchParams(router.asPath.split('?')[0])
    : undefined;
  const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
  paramsDecoded.dateInputType = DateInputType.FULL;

  const searchParameters =
    Object.keys(Object.fromEntries(query.entries())).length > 0
      ? {
          ...paramsDecoded,
          ...params
        }
      : { ...params };

  const transformBestPricePackagesToDestinations = (
    packageModels: ApiPackageModel[]
  ): DestinationRowProps[] => {
    const results: DestinationRowProps[] = [];
    const sortedDestinations: Map<string, Map<string, ApiPackageModel[]>> = new Map();
    const sortedChildDestinations: Map<string, ApiPackageModel[]> = new Map();

    packageModels.forEach(packageModel => {
      const packageModelGeoCode = packageModel.geoAssignment?.geoUnit.code;
      if (packageModelGeoCode) {
        const relatedChildPackages = sortedChildDestinations.get(packageModelGeoCode);
        if (relatedChildPackages) {
          relatedChildPackages.push(packageModel);
          sortedChildDestinations.set(packageModelGeoCode, relatedChildPackages);
        } else {
          sortedChildDestinations.set(packageModelGeoCode, [packageModel]);
        }
      }
    });

    sortedChildDestinations.forEach((value, key) => {
      const parentDestination = destinations.find(
        destination => destination.code == key || destination.children[key]
      );

      if (parentDestination) {
        const relatedDestinations = sortedDestinations.get(parentDestination.code);

        if (relatedDestinations) {
          // update already existing Map of related items
          relatedDestinations.set(key, value);
          sortedDestinations.set(parentDestination.code, relatedDestinations);
        } else {
          // init Map of related items and add first values
          sortedDestinations.set(parentDestination.code, new Map([[key, value]]));
        }
      }
    });

    // transform sorted packages to proper model
    sortedDestinations.forEach((relatedBestPricePackages, parentDestinationCode) => {
      const destinationImage = globalMGLProps?.cmsDestinations?.find(
        cmsDestination => cmsDestination.code === parentDestinationCode
      )?.picture;

      const minimalPriceInRelatedDestinations = [...relatedBestPricePackages.values()]
        .flat()
        .reduce((previousValue, currentValue) =>
          previousValue.price.finalPrice < currentValue.price.finalPrice
            ? previousValue
            : currentValue
        ).price;

      const destinations_children = destinations
        .map(destination => Object.keys(destination.children).map(key => destination.children[key]))
        .flat();
      const destinationDetails: DestinationDetailsProps[] = [];

      relatedBestPricePackages.forEach((bestPricePackages, destinationCode) => {
        destinationDetails.push({
          code: destinationCode,
          name: destinations_children.find(value => value.code === destinationCode)?.name,
          price: bestPricePackages.reduce((previousValue, currentValue) =>
            previousValue.price.finalPrice < currentValue.price.finalPrice
              ? previousValue
              : currentValue
          ).price,
          packages: bestPricePackages
        });
      });

      results.push({
        price: minimalPriceInRelatedDestinations,
        destinations: destinationDetails,
        destinationImage: destinationImage,
        name: destinations.find(value => value.code === parentDestinationCode)?.name
      });
    });

    return results;
  };

  function onDestinationSelect(codes: string[]): void {
    const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    const resultDestinations: DestinationItem[] = [];

    codes.forEach(code => {
      const foundDestination = findDestination(destinations, code);
      if (foundDestination) {
        resultDestinations.push(
          ...foundDestination.filter(fd => !resultDestinations.find(rd => fd.code === rd.code))
        );
      }
    });

    paramsDecoded.destinations = resultDestinations;
    const queryParams = PackageParamsTransformer.encode(paramsDecoded);

    if (redirectionUrl) {
      router.push(`${redirectionUrl}?${queryParams}`);
    }
  }

  return (
    <>
      <Row className="iso_destinations-container">
        {packages.length ? (
          <Col className="">
            <div className="iso_destinations__total-trips align-items-baseline px-2">
              <span className="iso_destinations__total-trips-counter mr-1">{packages.length}</span>
              <span className="iso_destinations__total-trips-label">{t(Keys.tripsFound)}</span>
            </div>
            <div className={'iso_destinations-container__list'}>
              {transformBestPricePackagesToDestinations(packages).map(destination => (
                <DestinationRow
                  {...destination}
                  onClick={onDestinationSelect}
                  key={`country-${destination.name}`}
                />
              ))}
            </div>
          </Col>
        ) : (
          <>
            {!isLoading && (
              <Col className="px-2 py-3">
                <Row className="justify-content-center">
                  <div className="iso_destinations__empty_results_info">
                    {t(Keys.noPackagesFoundInformation)}
                  </div>
                </Row>
              </Col>
            )}
          </>
        )}
      </Row>
      {isLoading && (
        <Overlay
          className="best-price__list-selections__spinner"
          positionFixed={true}
          text={t(Keys.onContinueLoadingText)}
          zIndex={1020}
          customSpinner={
            <div className="best-price-custom-spinner">
              <div />
              <div />
              <div />
              <div />
            </div>
          }
        />
      )}
    </>
  );
};

export default Destinations;
