import { Col, CustomInput, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useAuth } from '@ibe/components';

import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/Checkout-B2B.json.keys';

export interface PreferenceSectionValues {
  firstPreference: boolean;
  secondPreference: boolean;
  thirdPreference: boolean;
  fourthPreference: boolean;
  fifthPreference: boolean;
}

interface PreferencesSectionProps {
  onCheckboxChange: (key: string) => void;
  values: PreferenceSectionValues;
}

const PreferencesSection = (props: PreferencesSectionProps): JSX.Element => {
  const { t } = useTranslationMGL('Checkout-B2B');
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  const { onCheckboxChange, values } = props;

  const auth = useAuth();

  useEffect(() => {
    setIsSectionVisible(auth.authService.isLoggedIn);
  }, [auth.authService.isLoggedIn]);

  const preferences = new Map<string, string>([
    ['firstPreference', t(Keys.firstPreference)],
    ['secondPreference', t(Keys.secondPreference)],
    ['thirdPreference', t(Keys.thirdPreference)],
    ['fourthPreference', t(Keys.fourthPreference)],
    ['fifthPreference', t(Keys.fifthPreference)]
  ]);

  function buildPreferences(): JSX.Element[] {
    const preferencesItems: JSX.Element[] = [];

    preferences.forEach((value, key) => {
      const isSelected = values[key as keyof PreferenceSectionValues];

      preferencesItems.push(
        <Row key={key}>
          <CustomInput
            id={key}
            style={{ zIndex: 0 }}
            className="preferencesSection_customInput"
            type="checkbox"
            checked={isSelected}
            onChange={() => onCheckboxChange(key)}
            onClick={e => e.stopPropagation()}
          />
          <h4 className="pb-0 mb-0 preferencesSection_item">{value}</h4>
        </Row>
      );
    });
    return preferencesItems;
  }

  return (
    <>
      {isSectionVisible && (
        <Row className="formContainer mb-4 preferencesSection_container">
          <Col xs={12}>
            <Row>
              <h4>{t(Keys.headlineText)}</h4>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>{t(Keys.disclaimerText)}</Row>
          </Col>
          <Col>{buildPreferences()}</Col>
        </Row>
      )}
    </>
  );
};

export default PreferencesSection;
