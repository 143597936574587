/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-18 16:38:09 */

const Keys = {
  tripsFound: 'tripsFound',
  hotel: 'hotel',
  hotel_plural: 'hotel_plural',
  from: 'from',
  destination: 'destination',
  destination_plural: 'destination_plural',
  noPackagesFoundInformation: 'noPackagesFoundInformation',
  onContinueLoadingText: 'onContinueLoadingText',
  allDestinations: 'allDestinations'
}; export default Keys;