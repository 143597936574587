import {
  ApiActivity,
  ApiActivityType,
  ApiBaseData,
  ApiItemType,
  ApiNote,
  ApiNoteType,
  ApiPaymentOption,
  ApiPreparePaymentRequestFromJSON,
  ApiTraveler,
  ApiTravelerToJSON
} from '@ibe/api';
import {
  Error,
  FormRef,
  ImageProgressbar,
  MEDIAQUERY_DEFAULTS,
  Overlay,
  OverlayContainer,
  ScrollerService,
  Success,
  useApi,
  useAuth,
  useMediaQuery
} from '@ibe/components';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Element as ScrollTo } from 'react-scroll/modules';
import { CheckoutStepComponentProps } from '@/Models/CheckoutStep';
import KeysB2B from '@/Translations/generated/en/Checkout-B2B.json.keys';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import BackButton from './BackButton';
import { FormItemsProps } from '@/Config/travellerFormConfig';
import { useMount } from 'react-use';
import TravelerForms from './TravelerForms';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { useWorkflowService } from '@/Util/contexts';
import { getAge } from '@/components/checkout/externals';
import CheckoutPriceInfo from '@/components/checkout/CheckoutPriceInfo';
import { getPaymentStatus } from '@/Util/getPaymentStatus';
import VoucherSection from '@/components/checkout/VoucherSection';
import TACSection from '@/components/checkout/TACSection';
import BookingUtils from '@/Util/BookingUtils';
import { configService } from '@/Config/config';
import PreferencesSection, {
  PreferenceSectionValues
} from '@/components/checkout/PreferencesSection';

export const scrollElementErrorTravelerBase = 'travelerErrorScroller';

const preferencesTranslations = new Map<string, Map<string, string>>([
  [
    'en',
    new Map<string, string>([
      ['firstPreference', ' Preference for double bed'],
      ['secondPreference', 'Preference for 2 single beds'],
      ['thirdPreference', 'Quiet room if possible'],
      ['fourthPreference', 'Preference for floor as high as possible'],
      ['fifthPreference', 'Preference for ground floor']
    ])
  ],
  [
    'fr',
    new Map<string, string>([
      ['firstPreference', 'Préférence pour le lit double'],
      ['secondPreference', 'Préférence pour 2 lits simples'],
      ['thirdPreference', 'Si possible chambre au calme'],
      ['fourthPreference', 'Préférence pour un étage élévé'],
      ['fifthPreference', 'Préférence pour une chambre en rez-de-chaussée']
    ])
  ],
  [
    'nl',
    new Map<string, string>([
      ['firstPreference', 'Voorkeur voor tweepersoonsbed'],
      ['secondPreference', 'Voorkeur voor 2 éénpersoonsbedden'],
      ['thirdPreference', 'Indien mogelijk rustige kamer'],
      ['fourthPreference', 'Voorkeur voor een zo hoog mogelijke verdieping'],
      ['fifthPreference', 'Voorkeur voor gelijkvloers']
    ])
  ]
]);

const Traveler = observer(function Traveler(props: CheckoutStepComponentProps) {
  const { store } = props;
  const booking = store.booking;
  const auth = useAuth();
  const api = useApi();
  const isDesktopView = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });
  const isUserLoggedIn = auth.authState.isLoggedIn;
  const { t } = useTranslationMGL(isUserLoggedIn ? 'Checkout-B2B' : 'Checkout');

  const { workflow, setNextStepSlug, setPaymentResponse } = useWorkflowService();
  const [travelers, setTravelers] = useState<ApiTraveler[]>(booking?.travelers || []);
  const [externalData, setExternalData] = useState<{
    COUNTRIES: ApiBaseData[];
    SALUTATIONS: ApiBaseData[];
  }>({ COUNTRIES: [], SALUTATIONS: [] });

  const formRefs = useRef<Array<FormRef<Record<string, unknown>>>>([]);

  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [activityError, setActivityError] = useState<boolean | undefined>(undefined);
  const [activitySuccess, setActivitySuccess] = useState<boolean | undefined>(undefined);

  const [selectedPreferencesObj, setSelectedPreferencesObj] = useState<PreferenceSectionValues>({
    firstPreference: false,
    secondPreference: false,
    thirdPreference: false,
    fourthPreference: false,
    fifthPreference: false
  });

  function updateSelectedPreferencesObj(key: string): void {
    setSelectedPreferencesObj({
      ...selectedPreferencesObj,
      [key]: !selectedPreferencesObj[key as keyof PreferenceSectionValues]
    });
  }

  const [isTACFirstBoxChecked, setIsTACFirstBoxChecked] = useState<boolean>(false);
  const [isTACSecondBoxChecked, setIsTACSecondBoxChecked] = useState<boolean>(false);

  const [showFirstSlotError, setShowFirstSlotError] = useState<boolean>(false);
  const [showSecondSlotError, setShowSecondSlotError] = useState<boolean>(false);

  const scrollElementError = 'travellerErrorScroller';
  const scrollToError = (errorId: string): void => {
    ScrollerService.scrollTo(errorId, { offset: -100 });
  };

  useEffect((): void => {
    setTravelers(store.booking?.travelers || []);
  }, [booking]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useMount(async () => {
    await store.loadConfigurationData();
    await store.loadCountries();
    setExternalData({
      COUNTRIES: store.countries || [],
      SALUTATIONS: store.titles || []
    });
  });

  function getPreferencesNoteText(keysOfSelectedPreferences: string[]): string {
    let preferencesNoteText = '';

    [...preferencesTranslations.keys()].forEach(language => {
      preferencesNoteText += language + ' - ';

      keysOfSelectedPreferences.forEach(keyOfSelectedPreferences => {
        const preferenceText = preferencesTranslations.get(language)?.get(keyOfSelectedPreferences);

        preferencesNoteText += preferenceText + ', ';
      });

      preferencesNoteText += '\n';
    });
    return preferencesNoteText;
  }

  const onSubmit = async (): Promise<void> => {
    validateTAC();

    if (showFirstSlotError || showSecondSlotError) {
      return;
    }

    try {
      setLoading(true);
      const formResults = (
        await Promise.all(formRefs.current.map(ref => ref?.triggerSubmit()))
      ).filter(item => !!item);

      if (formResults.some(result => Object.keys(result).length === 0)) {
        const index = formResults.findIndex(result => Object.keys(result).length === 0);
        scrollToError(scrollElementErrorTravelerBase + index);
        return;
      }

      const AllTravelers: ApiTraveler[] = formResults.map(item => {
        const result = item as FormItemsProps;
        const travelerInfoInBooking = travelers.find(
          (traveler: ApiTraveler) => traveler.id === result.id
        );
        if (travelerInfoInBooking) {
          return ApiTravelerToJSON({
            ...travelerInfoInBooking,
            birthDate: result.birthDate,
            age: getAge(result.birthDate),
            salutation: result.salutation,
            communicationDetails: {
              phone: result.phone,
              email: result.email
            },
            firstName: result.firstname,
            lastName: result.lastname,
            address: {
              city: result.city,
              countryCode: result.country,
              postalCode: result.zipCode,
              street: result.street
            }
          });
        }
      });

      await store.updateTravelers(AllTravelers);

      if (auth.authService.isLoggedIn) {
        await addPreferencesToBooking();

        let url;
        if (store.booking && store.booking.id) {
          url = await api.preparePayment(
            store.booking.id,
            ApiPreparePaymentRequestFromJSON({
              isDeposit: false,
              isQuotationBooking: false,
              paymentMethod: ApiPaymentOption.INVOICE
            })
          );
        }
        if (url) {
          setPaymentResponse(getPaymentStatus(url));
        }
        setNextStepSlug(workflow.nextStepSlug || '');
      } else {
        if (store.booking && store.booking.id) {
          const activity: ApiActivity = {
            bookingId: store.booking.id,
            type: ApiActivityType.BOOKINGRESERVATION
          };
          await api
            .createActivity(activity)
            .then(() => setActivitySuccess(true))
            .catch(() => setActivityError(true));
        }
      }
    } catch (e) {
      setError(t(Keys.SummaryBookingError));
      scrollToError(scrollElementError);
    } finally {
      setLoading(false);
    }
  };

  const addPreferencesToBooking = async (): Promise<void> => {
    const keysOfSelectedPreferences: string[] = [];

    Object.keys(selectedPreferencesObj).forEach(function (key, index) {
      if (selectedPreferencesObj[key as keyof PreferenceSectionValues]) {
        keysOfSelectedPreferences.push(key);
      }
    });

    if (keysOfSelectedPreferences.length) {
      const hotelServiceId = store.booking?.bookedItems.filter(
        item => item.itemType === ApiItemType.HOTEL
      )[0].id;

      if (hotelServiceId) {
        await store.updateServiceNotes(
          {
            serviceId: hotelServiceId,
            notes: Array.of<ApiNote>({
              id: '',
              type: ApiNoteType.SPECIALREQUEST,
              text: getPreferencesNoteText(keysOfSelectedPreferences)
            }),
            bookingNumber: store.booking?.id || ''
          },
          false
        );
      }
    }
  };

  const createQuoteBooking = async (): Promise<void> => {
    if (!store.booking) {
      setError(t(Keys.SummaryBookingError));
      return;
    }

    try {
      setLoading(true);
      let quoteBookingTravelers: ApiTraveler[];

      const multipleRooms = (
        BookingUtils.findBookedItemsByType(store.booking, ApiItemType.HOTEL) || []
      ).length;

      if (multipleRooms > 1) {
        quoteBookingTravelers = BookingUtils.buildTravelersForQuoteBookingWithMultiRoom(
          store.booking
        );
      } else {
        quoteBookingTravelers = BookingUtils.buildTravelersForQuoteBookingWithOneRoom(
          store.booking
        );
      }

      await store.updateTravelers(quoteBookingTravelers);

      if (auth.authService.isLoggedIn) {
        let url;
        if (store.booking && store.booking.id) {
          url = await api.preparePayment(
            store.booking.id,
            ApiPreparePaymentRequestFromJSON({
              isDeposit: false,
              isQuotationBooking: true,
              paymentMethod: ApiPaymentOption.INVOICE
            })
          );
        }
        if (url) {
          setPaymentResponse(getPaymentStatus(url));
        }
        setNextStepSlug(workflow.nextStepSlug || '');
      }
    } catch (e) {
      setError(t(Keys.SummaryBookingError));
      scrollToError(scrollElementError);
    } finally {
      setLoading(false);
    }
    return Promise.resolve();
  };

  const ProgressbarWrapper = observer(() => {
    const translatedSteps = store.steps.map(step => {
      return { ...step, description: t(step.description) };
    });
    return (
      <ImageProgressbar
        steps={translatedSteps}
        onClick={(index: number): void => {
          setNextStepSlug(store.handleNavigationClick(workflow, index - 1));
        }}
        showNotActiveIcon
        showDescriptionOnlyForActiveTab={!isDesktopView}
      />
    );
  });

  const ErrorWrapper = observer(() => (
    <>
      <ScrollTo name={scrollElementError}>{error && <Error message={error || ''} />}</ScrollTo>
    </>
  ));

  function validateTAC(): void {
    if (!isTACFirstBoxChecked) {
      setShowFirstSlotError(true);
    }

    if (!isTACSecondBoxChecked) {
      setShowSecondSlotError(true);
    }
  }

  const Nav = (): JSX.Element => {
    return (
      <Row className="d-sm-flex mt-4">
        <Col className="col-sm-4 col-12">
          <BackButton />
        </Col>
        <Col className="col-sm-8 col-12 d-flex justify-content-end">
          <Button
            className="navButton"
            color="primary"
            onClick={(): void => {
              onSubmit();
            }}
          >
            {auth.authService.isLoggedIn ? t(Keys.order) : t(Keys.sendRequest)}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <OverlayContainer className="checkout__container__wrapper">
        {!activityError && !activitySuccess && (
          <>
            <div className="checkout__container__content">
              <ProgressbarWrapper />
              <ErrorWrapper />
              {isUserLoggedIn && !configService.get().hideCreateQuotationBookingButton && (
                <div className="d-flex justify-content-end p-2">
                  <Button
                    className="createQuoteBookingButton"
                    color="primary"
                    onClick={() => createQuoteBooking()}
                  >
                    {t(KeysB2B.createQuoteBookingButtonLabel)}
                  </Button>
                </div>
              )}
              {store.booking && store.booking.travelers && store.booking.bookedItems?.length > 0 && (
                <div>
                  <div className="travelerForms_container">
                    <TravelerForms
                      travelers={travelers}
                      setTravelers={setTravelers}
                      formRefs={formRefs}
                      booking={store.booking}
                      externalData={externalData}
                      initialValues={travelers.map((traveler: ApiTraveler) => ({
                        firstname: traveler.firstName || '',
                        lastname: traveler.lastName || ''
                      }))}
                    />
                  </div>
                  <PreferencesSection
                    onCheckboxChange={updateSelectedPreferencesObj}
                    values={selectedPreferencesObj}
                  />
                  <VoucherSection store={store} />
                </div>
              )}
              <TACSection
                isFirstSlotChecked={isTACFirstBoxChecked}
                isSecondSlotChecked={isTACSecondBoxChecked}
                onFirstSlotCheckboxChange={() => {
                  setIsTACFirstBoxChecked(!isTACFirstBoxChecked);
                  setShowFirstSlotError(isTACFirstBoxChecked);
                }}
                onSecondSlotCheckboxChange={() => {
                  setIsTACSecondBoxChecked(!isTACSecondBoxChecked);
                  setShowSecondSlotError(isTACSecondBoxChecked);
                }}
                showFirstSlotError={showFirstSlotError}
                showSecondSlotError={showSecondSlotError}
              />

              {isDesktopView && <Nav />}
            </div>
            <CheckoutPriceInfo
              store={store}
              next={onSubmit}
              backButton={<BackButton className={'w-100'} />}
            />
            {isLoading && <Overlay />}
          </>
        )}
      </OverlayContainer>
      {activitySuccess && <Success message={t(Keys.activityCreatedSuccessfully)} />}
      {activityError && <Error message={t(Keys.activityCreatedError)} />}
    </>
  );
});

export default Traveler;
