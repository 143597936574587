import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CollapsibleRef } from '@ibe/components';
import React, { ReactNode, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
  faCalendarAlt,
  faMapMarkerAlt,
  faPlaneDeparture,
  faUserFriends,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import isClient from '../../../Util/globals';
import { useMount } from 'react-use';

const RenderSearch = (
  CategorySelect: ReactNode,
  OriginSelect: ReactNode,
  DestinationSelect: ReactNode,
  TravelDatePicker: ReactNode,
  TravelMonthPicker: ReactNode,
  TravelerPicker: ReactNode,
  SearchButton: ReactNode,
  OriginCheckboxes: ReactNode,
  DateRangePickerSingleSelect: ReactNode,
  collapsibleRef?: CollapsibleRef,
  message?: string
): JSX.Element => {
  const [mounted, setMounted] = useState<boolean>(false);

  useMount(() => {
    setMounted(true);
  });

  const closeCollapsible = () => {
    if (collapsibleRef?.current) {
      collapsibleRef.current.closeCollapsible();
    }
  };

  return (
    <>
      <div className="iso__service-search justify-content-center">
        <div className="iso__service-search__closeCollapsible">
          <Button color="link" className="align-self-end" onClick={closeCollapsible}>
            X
          </Button>
        </div>
        {isClient() && mounted && (
          <Row className="iso__service-search__bar-container">
            <Col className="iso__service-search__row__1__col__2 mb-xl-0 mb-3" sm={6} xl={2}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mr-2 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100 overflow-hidden">{DestinationSelect}</div>
              </div>
            </Col>
            <Col className="iso__service-search__row__2__col__2 mb-xl-0 mb-3" sm={6} xl={3}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faUserFriends}
                  className="mr-1 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100">{TravelerPicker}</div>
              </div>
            </Col>
            <Col className="iso__service-search__row__2__col__1 mb-xl-0 mb-3" sm={6} xl={3}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="mr-2 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100 overflow-hidden">{TravelDatePicker}</div>
              </div>
            </Col>
            <Col className="iso__service-search__row__1__col__1 mb-xl-0 mb-3" sm={6} xl={2}>
              <div className="d-flex align-items-end position-relative iso__service-search__column-headline">
                <FontAwesomeIcon
                  icon={faPlaneDeparture}
                  className="mr-2 mb-2 d-inline iso__service-search__icon"
                />
                <div className="w-100">{OriginCheckboxes}</div>
              </div>
            </Col>
            <Col xl={2} className="d-flex flex-column">
              <div className="mt-auto">{SearchButton}</div>
            </Col>
          </Row>
        )}
      </div>
      {message && (
        <div className="alert alert-info">
          <FontAwesomeIcon icon={faExclamationCircle} />
          &nbsp;{message}
        </div>
      )}
    </>
  );
};

export default RenderSearch;
