import { ApiBestPricePackageModel, ApiPackageModel } from '@ibe/api';
import { PackageItemSelection } from '@ibe/components';
import PackageListItem from './PackageListItem';

const RenderListItem = (
  item: ApiPackageModel,
  index?: number,
  onItemSelect?: (selection: Partial<PackageItemSelection>) => void
): JSX.Element => {
  const bestPriceItem = item as ApiBestPricePackageModel;
  return (
    <div key={item.id} className="mb-3">
      <PackageListItem item={bestPriceItem} onSelect={onItemSelect} />
    </div>
  );
};

export default RenderListItem;
