/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-18 16:38:09 */

const Keys = {
  adult: 'adult',
  adult_plural: 'adult_plural',
  child: 'child',
  child_plural: 'child_plural',
  destinationsLabel: 'destinationsLabel',
  destinationsLabel_plural: 'destinationsLabel_plural',
  destinationsPlaceholder: 'destinationsPlaceholder',
  search: 'search',
  dates: 'dates',
  noResultsFound: 'noResultsFound',
  searchError: 'searchError'
}; export default Keys;