/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-18 16:38:09 */

const Keys = {
  errorChildrenAge: 'errorChildrenAge',
  errorInfantCount: 'errorInfantCount',
  errorInvalidName: 'errorInvalidName',
  errorInvalidFirstMiddleName: 'errorInvalidFirstMiddleName',
  errorInvalidChar: 'errorInvalidChar',
  errorRequiredField: 'errorRequiredField',
  errorInvalidAdult: 'errorInvalidAdult',
  errorInvalidChild: 'errorInvalidChild',
  errorInvalidInfant: 'errorInvalidInfant',
  errorEmail: 'errorEmail',
  errorPhone: 'errorPhone',
  salutation: 'salutation',
  next: 'next',
  participant: 'participant',
  inputRequired: 'inputRequired',
  male: 'male',
  female: 'female',
  other: 'other',
  firstname: 'firstname',
  middlename: 'middlename',
  lastname: 'lastname',
  nationality: 'nationality',
  dateOfBirth: 'dateOfBirth',
  email: 'email',
  phone: 'phone',
  street_houseNumber: 'street_houseNumber',
  city: 'city',
  zipCode: 'zipCode',
  country: 'country',
  cancel: 'cancel',
  continue: 'continue',
  modalLabel: 'modalLabel'
}; export default Keys;