/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-07-18 16:38:09 */

const Keys = {
  headline: 'headline',
  merchant: 'merchant',
  user: 'user',
  password: 'password',
  submit: 'submit',
  forgotPassword: 'forgotPassword',
  agency: 'agency',
  email: 'email',
  forgotPasswordSuccessText: 'forgotPasswordSuccessText',
  forgotPasswordFailText: 'forgotPasswordFailText',
  requiredField: 'requiredField'
}; export default Keys;